import client from "../libs/client";
import { billingNewGatewayInterface } from "../../../corelogic/usecases/billing-new/billingNewGatewayInterface";
import { Invoice } from "../../../corelogic/models/entities/billing-new/invoice";

export default class APIBillingNewGateway
  implements billingNewGatewayInterface
{
  error: any;

  setError = (error: boolean) => {
    this.error = error;
  };

  async getInvoices(participantId: number): Promise<Invoice[] | null> {
    const { data: invoices } = await client.get(
      `/participants/${participantId}/billing/invoices`
    );
    return invoices.map((invoice: any) => {
      return new Invoice(
        invoice.id,
        invoice.number,
        invoice.periodStart,
        invoice.periodEnd,
        invoice.amount,
        invoice.synthesisDocumentId
      );
    });
  }
  async downloadInvoice(
    participantId: number,
    invoiceId: number, lang?: string
  ): Promise<void> {
    const { data: invoiceUrl } = await client.get(
      `/participants/${participantId}/billing/invoices/${invoiceId}/download?language=${lang}`
    );
    window.open(invoiceUrl);
  }
}

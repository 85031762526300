import { call, getContext, put, select, takeLatest } from "redux-saga/effects";
import { setError } from "../error/errorActions";
import { billingNewGatewayInterface } from "./billingNewGatewayInterface";
import { selectCurrentParticipantId } from "../participants/participantsSelector";
import { handleErrorMessage } from "../../../adapters/primary/libs/handleErrorMessage";
import { BillingNewActions } from "./billingNewActions";
import { Invoice } from "../../models/entities/billing-new/invoice";
import { DOWNLOAD_INVOICE, GET_INVOICES } from "./billingNewConstants";

function* getInvoices() {
  try {
    const participantId: number = yield select(selectCurrentParticipantId);
    const billingNewGatewayInterface: billingNewGatewayInterface =
      yield getContext("billingNewGatewayContext");
    const invoices: Invoice[] = yield call(
      billingNewGatewayInterface.getInvoices,
      participantId
    );
    yield put(BillingNewActions.setInvoices(invoices));
  } catch (error: any) {
    console.error("Fetch Invoices...", error);
    yield put(
      setError({
        status: error?.response?.status,
        message:
          "[GET Invoices] : " + handleErrorMessage(error?.response?.status),
      })
    );
  }
}
function* downloadInvoice(action: any) {
  try {
    const participantId: number = yield select(selectCurrentParticipantId);
    const { invoiceId, lang } = action.payload;
    const billingNewGatewayInterface: billingNewGatewayInterface =
      yield getContext("billingNewGatewayContext");
    yield call(
      billingNewGatewayInterface.downloadInvoice,
      participantId,
      invoiceId,
      lang
    );
  } catch (error: any) {
    console.error(error);
    yield put(
      setError({
        status: error.response.status,
        message:
          "[GET DownloadInvoice] : " +
          handleErrorMessage(error.response.status),
      })
    );
  }
}

export function* downloadInvoiceSaga() {
  yield takeLatest(DOWNLOAD_INVOICE, downloadInvoice);
}
export function* getInvoicesSaga() {
  yield takeLatest(GET_INVOICES, getInvoices);
}

export default [getInvoicesSaga, downloadInvoiceSaga];

import {combineReducers} from 'redux';

import authenticationReducer from "../usecases/authentication/authenticationReducer";
import errorReducer from "../usecases/error/errorReducer";
import {connectRouter} from 'connected-react-router';
import operationReducer from "../usecases/operation/operationReducer";
import billingReducer from "../usecases/billing/billingReducer";
import documentReducer from "../usecases/document/documentReducer";
import consumptionReducer from "../usecases/consumption/consumptionReducer";
import informationReducer from "../usecases/information/informationReducer";
import localProductionReducer from '../usecases/localProduction/localProductionReducer';
import helpReducer from '../usecases/help/helpReducer';
import contactReducer from '../usecases/contact/contactReducer';
import participantsReducer from "../usecases/participants/participantsReducer";
import datasReducer from "../usecases/datas/datasReducer";
import navigationReducer from "../usecases/navigation/navigationReducer";
import billCheckReducer from "../usecases/bill-check/billCheckReducer";
import billingNewReducer from '../usecases/billing-new/billingNewReducer';

const rootReducer = (history:any) => combineReducers({
    participants : participantsReducer,
    operation: operationReducer,
    authentication: authenticationReducer,
    billing : billingReducer,
    billingNew: billingNewReducer,
    billCheck : billCheckReducer,
    datas : datasReducer,
    document : documentReducer,
    information: informationReducer,
    consumption : consumptionReducer,
    localProduction : localProductionReducer,
    contact : contactReducer,
    router : connectRouter(history),
    help :helpReducer,
    error: errorReducer,
    navigation: navigationReducer,
});

export default rootReducer;

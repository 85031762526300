import { billingNewGatewayInterface } from "../../../corelogic/usecases/billing-new/billingNewGatewayInterface";
import { Invoice } from "../../../corelogic/models/entities/billing-new/invoice";

export default class APIBillingNewGateway
  implements billingNewGatewayInterface
{
  error: any;

  setError = (error: boolean) => {
    this.error = error;
  };

  async getInvoices(participantId: number): Promise<Invoice[] | null> {
    return [
      new Invoice(0, "I-0", "2020-04-10", "2020-05-09", 6.252150347624642, 6),
      new Invoice(1, "I-1", "2020-05-10", "2020-06-09", 7.252150347624642, 7),
    ];
  }
  async downloadInvoice(
    participantId: number,
    invoiceId: number
  ): Promise<void> {
    return Promise.resolve(undefined);
  }
}

import { SET_INVOICES } from "./billingNewConstants";
import { ActionWithPayload } from "../../redux/createAction";
import { BillingNew } from "../../models/types/BillingNew";

const initialState = {
  invoices: [],
};

export default function billingNewReducer(
  state = initialState,
  action: ActionWithPayload<BillingNew>
) {
  switch (action.type) {
    case SET_INVOICES: {
      return { ...state, invoices: action.payload.invoices };
    }
    default:
      return state;
  }
}

export class Invoice {
  _id: number;
  _number: string;
  _periodStart: string;
  _periodEnd: string;
  _price: number;
  _documentId: number;

  constructor(
    id: number,
    number: string,
    periodStart: string,
    periodEnd: string,
    price: number,
    documentId: number
  ) {
    this._id = id;
    this._number = number;
    this._periodEnd = periodEnd;
    this._periodStart = periodStart;
    this._price = price;
    this._documentId = documentId;
  }
}

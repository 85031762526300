import {all, fork} from 'redux-saga/effects';
import authenticationSaga from "../usecases/authentication/authenticationSaga";
import operationSaga from "../usecases/operation/operationSaga";
import billingSaga from "../usecases/billing/billingSaga";
import documentSaga from "../usecases/document/documentSaga";
import consumptionSaga from "../usecases/consumption/consumptionSaga";
import informationSaga from "../usecases/information/informationSaga";
import localProductionSaga from "../usecases/localProduction/localProductionSaga";
import helpSaga from "../usecases/help/helpSaga";
import contactSaga from "../usecases/contact/contactSaga";
import participantsSaga from "../usecases/participants/participantsSaga";
import datasSaga from "../usecases/datas/datasSaga";
import navigationSaga from "../usecases/navigation/navigationSaga";
import billCheckSaga from "../usecases/bill-check/billCheckSaga";
import billingNewSaga from '../usecases/billing-new/billingNewSaga';

const sagas = [
    ...operationSaga,
    ...authenticationSaga,
    ...billingSaga,
    ...billingNewSaga,
    ...documentSaga,
    ...consumptionSaga,
    ...informationSaga,
    ...localProductionSaga,
    ...helpSaga,
    ...contactSaga,
    ...participantsSaga,
    ...datasSaga,
    ...navigationSaga,
    ...billCheckSaga

];

export default function rootSaga() {
    return function* () {
        yield all(
            [
                ...sagas,
            ].map(fork)
        );
    }
}

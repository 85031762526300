import {
  DOWNLOAD_INVOICE,
  GET_INVOICES,
  SET_INVOICES,
} from "./billingNewConstants";
import { Invoice } from "../../models/entities/billing-new/invoice";

export let BillingNewActions = {
  getInvoices() {
    return { type: GET_INVOICES };
  },
  setInvoices(invoices: Invoice[]) {
    return { type: SET_INVOICES, payload: { invoices } };
  },
  downloadInvoice(invoiceId: number, lang?: string) {
    return { type: DOWNLOAD_INVOICE, payload: { invoiceId, lang } };
  },
};
